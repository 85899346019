import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { AvatarModule } from 'primeng/avatar';
import { StyleClassModule } from 'primeng/styleclass';
import { Sidebar } from 'primeng/sidebar';
import { Router } from '@angular/router';

@Component({
     selector: 'app-side-menu',
     standalone: true,
     imports: [SidebarModule, ButtonModule, RippleModule, AvatarModule, StyleClassModule],
     templateUrl: './side-menu.component.html',
     styleUrl: './side-menu.component.scss'
})

export class SideMenuComponent {
     @ViewChild('sidebarRef') sidebarRef!: Sidebar;
     @Input() sidebarVisible = true;
     @Output() emitSideBarStat = new EventEmitter<Event>();

     constructor(
          private router: Router,
     ) { }

     closeCallback(e: MouseEvent): void {
          this.emitSideBarStat.emit();
          this.sidebarVisible = !this.sidebarVisible;
     }

     goToPage(page: string) {
          if (page) {
               switch (page) {
                    case 'dashboard':
                         this.router.navigate(['/dashboard']);
                         break;
                    case 'organisations':
                         this.router.navigate(['/organisations']);
                         break;
                    case 'organisation-subscriptions':
                         this.router.navigate(['/organisation-subscriptions']);
                         break;
                    case 'subscriptions-master':
                         this.router.navigate(['/subscriptions-master']);
                         break;
                    case 'manage-users':
                         this.router.navigate(['/manage-users']);
                         break;
                    case 'onboard-users':
                         this.router.navigate(['/onboard-users']);
                         break;
                    case 'gva-roles':
                         this.router.navigate(['/gva-roles']);
                         break;
                    case 'plans':
                         this.router.navigate(['/plans']);
                         break;
                    case 'plan-roles':
                         this.router.navigate(['/plan-roles']);
                         break;
                    case 'subscription-types':
                         this.router.navigate(['/subscription-types']);
                         break;
                    case 'end-of-day-runs':
                         this.router.navigate(['/end-of-day-runs']);
                         break;
                    default:
                         this.router.navigate(['/dashboard']);
                         break;
               }
          }
     }

}