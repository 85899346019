import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth/index';

export const authGuard: CanActivateFn = (route, state) => {
     const authService = inject(AuthService);
     const router = inject(Router);

     if (authService.isAuth.value == false) {
          router.navigate(['/']);
          return false;
     }

     return authService.isAuth.value;
};