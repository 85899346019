<span class="profileConatiner">
     <div class="userDetails">
          <p-menu #menu [model]="profileMenuitems" [popup]="true">
               <ng-template pTemplate="item" let-item>
                    <a pRipple class="flex align-items-center p-menuitem-link">
                         <span [class]="item.icon"></span>
                         <span class="ml-2">{{ item.label }}</span>
                    </a>
               </ng-template>
               <ng-template pTemplate="end">
                    <p-divider />
                    <div class="themeContainer">
                         <span class="ml-4">Dark Theme</span>
                         <span class="ml-4">
                              <p-inputSwitch [(ngModel)]="checked" (onChange)="themeChanged($event)" />
                         </span>
                    </div>
               </ng-template>
          </p-menu>
          <span class="font-semibold mr-2">{{shortName}}</span>
     </div>
     <img src="/assets/images/icons/profile-light.png" alt="Manage Profile" (click)="menu.toggle($event)" class="profileImg">
</span>