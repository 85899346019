import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthorizedLayoutComponent } from './components/authorized-layout/authorized-layout.component';
import { LoginLayoutComponent } from './components/login-layout/login-layout.component';
import { ToastComponent } from './components/toast/toast.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { PageLayout } from './types-enums/enums/page-layout';
import { AppSettingsService } from './services/app-settings.service';
import { PageLayoutService } from './services/page-layout.service';
import { AuthService } from './services/auth';

@Component({
     selector: 'app-root',
     standalone: true,
     imports: [CommonModule, RouterOutlet, AuthorizedLayoutComponent, LoginLayoutComponent, ToastComponent, ProgressBarModule],
     templateUrl: './app.component.html',
     styleUrl: './app.component.scss'
})

export class AppComponent implements OnInit {
     title = 'GVA-System';
     showProgress: boolean = false;
     readonly PageLayout = PageLayout;

     constructor(
          private cdr: ChangeDetectorRef,
          private appSettingsService: AppSettingsService,
          public pageLayoutService: PageLayoutService,
          public authService: AuthService
     ) { }

     ngOnInit() {
          this.appSettingsService.isLoading.subscribe((res) => {
               this.showProgress = res;
               this.cdr.detectChanges();
          });
     }
     
}