import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarModule } from 'primeng/progressbar';
import { MessageService } from 'primeng/api';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { ProfileMenuComponent } from '../profile-menu/profile-menu.component';
import { SideMenuComponent } from '../side-menu/side-menu.component';
import { AppSettingsService } from '../../services/app-settings.service';

@Component({
     selector: 'app-authorized-layout',
     standalone: true,
     imports: [CommonModule, ProgressBarModule, BreadcrumbComponent, ProfileMenuComponent, SideMenuComponent],
     providers: [MessageService],
     templateUrl: './authorized-layout.component.html',
     styleUrl: './authorized-layout.component.scss'
})

export class AuthorizedLayoutComponent implements OnInit {
     sidebarVisible: boolean = true;
     @HostListener('window:resize', ['$event'])
     onResize(event: Event) {
          if(window.innerWidth < 992) {
               this.sidebarVisible = this.sidebarVisible;
          } else {
               this.sidebarVisible = this.sidebarVisible;
          }
     }

     constructor(
          private appSettingsService: AppSettingsService
     ) { }

     ngOnInit() {
          this.appSettingsService.themeSelected.next('lara-light-blue');
     }

     toggleSideBar(event: Event) {
          this.sidebarVisible = !this.sidebarVisible;
     }
}