import { ApplicationConfig, ErrorHandler } from '@angular/core';
import { PreloadAllModules, provideRouter, withPreloading } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { gvaSystemInterceptor } from './utils/gvaSystem.interceptor';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HandleHTTPErrorService } from './services/handle-http-error.service';

export const appConfig: ApplicationConfig = {
     providers: [
          provideRouter(routes, withPreloading(PreloadAllModules)),
          provideAnimations(),
          provideHttpClient(withInterceptorsFromDi()),
          {
               provide: HTTP_INTERCEPTORS,
               useClass: gvaSystemInterceptor,
               multi: true
          },
          { provide: ErrorHandler, useClass: HandleHTTPErrorService  },
     ]
};