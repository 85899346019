import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { AppSettingsService } from "./app-settings.service";

@Injectable({
     providedIn: "root"
})

export class HandleHTTPErrorService {
     errorMessage = ''

     constructor(
          private appSettingsService: AppSettingsService
     ) {}

     public handleError(error: HttpErrorResponse): void {
          if(error.error instanceof ErrorEvent) {
               this.errorMessage = error.error.message;
               this.appSettingsService.toast.next({key:'error',severity:'error',summary: this.errorMessage});
          } else {
               const errorCode = error.status;
               switch (true) {
                    case (errorCode === 400):
                         if(error?.error?.user_specific_message) {
                              this.errorMessage = error.error.user_specific_message;
                         } else {
                              this.errorMessage = 'Something went wrong while processing your request. Try again in a few minutes or contact System Support - ' + error.status+': '+'Bad Request.'
                         }
                         this.appSettingsService.toast.next({key:'error',severity:'error',summary:this.errorMessage})
                         break;
                    case (errorCode === 401):
                         if(error?.error?.detail) {
                              this.errorMessage = error.error.detail;
                         } else if(error?.error?.user_specific_message) {
                              this.errorMessage = error.error.user_specific_message;
                         } else {
                              this.errorMessage = this.errorMessage = 'Something went wrong while processing your request. Try again in a few minutes or contact System Support - ' + error.status+': '+'Unauthorized.'
                         }
                         this.appSettingsService.toast.next({key:'error',severity:'error',summary:error.error.detail})
                         break;
                    case (errorCode < 419):
                         if(error?.error?.user_specific_message) {
                              this.errorMessage = error.error.user_specific_message;
                         } else {
                              this.errorMessage = 'Something went wrong while processing your request. Try again in a few minutes or contact System Support - ' + error.status;
                         }
                         this.appSettingsService.toast.next({key:'error',severity:'error',summary:this.errorMessage});
                         break;
                    case (errorCode > 420 && errorCode < 427):
                         if(error?.error?.user_specific_message) {
                              this.errorMessage = error.error.user_specific_message;
                         } else {
                              this.errorMessage = 'Something went wrong while processing your request. Try again in a few minutes or contact System Support - ' + error.status;
                         }
                         this.appSettingsService.toast.next({key:'error',severity:'error',summary:this.errorMessage});
                         break;
                    case (errorCode > 427 && errorCode < 430):
                         if(error?.error?.user_specific_message) {
                              this.errorMessage = error.error.user_specific_message;
                         } else {
                              this.errorMessage = 'Something went wrong while processing your request. Try again in a few minutes or contact System Support - ' + error.status;
                         }
                         this.appSettingsService.toast.next({key:'error',severity:'error',summary:this.errorMessage});
                         break;
                    case (errorCode === 431):
                         if(error?.error?.user_specific_message) {
                              this.errorMessage = error.error.user_specific_message;
                         } else {
                              this.errorMessage = 'Something went wrong while processing your request. Try again in a few minutes or contact System Support - ' + error.status;
                         }
                         this.appSettingsService.toast.next({key:'error',severity:'error',summary:this.errorMessage});
                         break;
                    case (errorCode === 451):
                         if(error?.error?.user_specific_message) {
                              this.errorMessage = error.error.user_specific_message;
                         } else {
                              this.errorMessage = 'Something went wrong while processing your request. Try again in a few minutes or contact System Support - ' + error.status;
                         }
                         this.appSettingsService.toast.next({key:'error',severity:'error',summary:this.errorMessage});
                         break;
                    case (errorCode > 499 && errorCode < 509):
                         if(error?.error?.user_specific_message) {
                              this.errorMessage = error.error.user_specific_message;
                         } else {
                              this.errorMessage = 'Something went wrong while processing your request. Try again in a few minutes or contact System Support - ' + error.status;
                         }
                         this.appSettingsService.toast.next({key:'error',severity:'error',summary:this.errorMessage});
                         break;
                    case (errorCode > 509 && errorCode < 512):
                         if(error?.error?.user_specific_message) {
                              this.errorMessage = error.error.user_specific_message;
                         } else {
                              this.errorMessage = 'Something went wrong while processing your request. Try again in a few minutes or contact System Support - ' + error.status;
                         }
                         this.appSettingsService.toast.next({key:'error',severity:'error',summary:this.errorMessage});
                         break;
                    default:
                         break;
               }
          }
     }
}