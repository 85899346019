import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MenuItem } from 'primeng/api';
import { AppSettingsService } from '../../services/app-settings.service';

@Component({
     selector: 'app-breadcrumb',
     standalone: true,
     imports: [CommonModule, BreadcrumbModule],
     templateUrl: './breadcrumb.component.html',
     styleUrl: './breadcrumb.component.scss'
})

export class BreadcrumbComponent implements OnInit {
     breadCrumbItems: MenuItem[] | undefined;
     private readonly destroySubject = new Subject();

     constructor(
          private appSettingsService: AppSettingsService
     ) { }

     ngOnInit() {
          this.appSettingsService.breadcrumb
          .pipe(takeUntil(this.destroySubject))
          .subscribe((res) => {
               this.breadCrumbItems = res;
          })
     }
}
