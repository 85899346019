import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { AppSettingsService } from '../../services/app-settings.service';
import { Subject, takeUntil } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ToastMsg } from '../../types-enums/types/common';

@Component({
     selector: 'app-toast',
     standalone: true,
     imports: [CommonModule, ToastModule],
     providers: [MessageService],
     templateUrl: './toast.component.html',
     styleUrl: './toast.component.scss'
})

export class ToastComponent implements OnInit {
     private readonly destroySubject = new Subject();
     toastData = <ToastMsg>{};
     constructor(
          private appSettingsService: AppSettingsService,
          private messageService: MessageService
     ) { }

     ngOnInit() {
          this.appSettingsService.toast
               .pipe(takeUntil(this.destroySubject))
               .subscribe((msg) => {
                    this.messageService.clear();
                    if (msg) {
                         if (!msg.hasOwnProperty('closable')) msg.closable = true;
                         if (!msg.hasOwnProperty('sticky') || !msg.sticky) msg.sticky = false;
                         if (msg.hasOwnProperty('forceClose') && msg.forceClose === true) {
                              this.messageService.clear();
                         } else {
                              this.toastData = msg;
                              if (this.toastData?.key != '') {
                                   this.messageService.add(this.toastData);
                              }
                         }
                    }
               })
     }

     onClose() {
          this.messageService.clear();
     }

}