<div class="flex justify-content-center">
     <p-toast position="bottom-center" key="error" [baseZIndex]="5000" styleClass="customToast" (onClose)="onClose()">
          <ng-template let-message pTemplate="headless">
               <div class="flex flex-column align-items-start errotToast toastOuter" style="flex: 1;">
                    <div class="flex justify-content-center align-items-center">
                         <span class="icon">
                              <img src="/assets/images/icons/toast-error.svg" alt="Error">
                         </span>
                         <span class="message">
                              {{ message.summary }}
                         </span>
                         <span (click)="onClose()" class="closeBtn" *ngIf="message.closable">
                              <img src="/assets/images/icons/toast-close.svg" alt="Close Message">
                         </span>
                    </div>
               </div>
          </ng-template>
     </p-toast>
     <p-toast position="bottom-center" key="success" [baseZIndex]="5000" styleClass="customToast" (onClose)="onClose()">
          <ng-template let-message pTemplate="headless">
               <div class="flex flex-column align-items-start successToast toastOuter" style="flex: 1;">
                    <div class="flex justify-content-center align-items-center">
                         <span class="icon">
                              <img src="/assets/images/icons/toast-success.svg" alt="Error">
                         </span>
                         <span class="message">
                              {{ message.summary }}
                         </span>
                         <span (click)="onClose()" class="closeBtn" *ngIf="message.closable">
                              <img src="/assets/images/icons/toast-close.svg" alt="Close Message">
                         </span>
                    </div>
               </div>
          </ng-template>
     </p-toast>
     <p-toast position="bottom-center" key="info" [baseZIndex]="5000" styleClass="customToast" (onClose)="onClose()">
          <ng-template let-message pTemplate="headless">
               <div class="flex flex-column align-items-start infoToast toastOuter" style="flex: 1;">
                    <div class="flex justify-content-center align-items-center">
                         <span class="icon">
                              <img src="/assets/images/icons/toast-info.svg" alt="Error">
                         </span>
                         <span class="message">
                              {{ message.summary }}
                         </span>
                         <span (click)="onClose()" class="closeBtn" *ngIf="message.closable">
                              <img src="/assets/images/icons/toast-close.svg" alt="Close Message">
                         </span>
                    </div>
               </div>
          </ng-template>
     </p-toast>
</div>