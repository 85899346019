import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { InputSwitchChangeEvent, InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { AppSettingsService } from '../../services/app-settings.service';
import { AuthService } from '../../services/auth';
import { SharedModule } from 'primeng/api';
import { DividerModule } from 'primeng/divider';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-menu',
  standalone: true,
  imports: [CommonModule, MenuModule, InputSwitchModule, FormsModule, SharedModule, DividerModule],
  templateUrl: './profile-menu.component.html',
  styleUrl: './profile-menu.component.scss'
})

export class ProfileMenuComponent implements OnInit {
     profileMenuitems: MenuItem[] | undefined;
     shortName: string = '';
     checked: boolean = false;

     constructor(
          private appSettingsService: AppSettingsService,
          public authService: AuthService,
          private router: Router,
     ) { }

     ngOnInit() {
          this.profileMenuitems = [
               {
                    label: 'Change Password',
                    icon: 'pi pi-key',
                    command: () => {
                         this.changePassword();
                     }
               },
               {
                    label: 'Sign Out',
                    icon: 'pi pi-sign-out',
                    command: () => {
                         this.signOut();
                     }
               }
          ];
          this.shortName = this.appSettingsService.getShortName();
     }

     themeChanged(event: InputSwitchChangeEvent) {
          if(this.checked) {
               this.changeTheme('dark');
          } else {
               this.changeTheme('light');
          }
     }

     changeTheme(option: string) {
          if(option === 'light') {
               this.appSettingsService.switchTheme('lara-light-blue');
          } else {
               this.appSettingsService.switchTheme('lara-dark-blue');
          }
     }

     changePassword() {
          this.router.navigate(['/changePassword']);
     }

     signOut() {
          this.authService.signOut();
     }
}