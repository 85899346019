<p-breadcrumb class="max-w-full" [model]="breadCrumbItems">
     <ng-template pTemplate="item" let-item>
          <span *ngIf="item?.icon">
               <span *ngIf="item?.url">
                    <a class="cursor-pointer" [routerLink]="item.url">
                         <i [class]="item.icon" class="breadCrumbHome"></i>
                    </a>
               </span>
               <span *ngIf="!item?.url">
                    <i [class]="item.icon"></i>
               </span>
          </span>
          <span *ngIf="item?.label">
               <span>{{item.label}}</span>
          </span>
     </ng-template>
     <ng-template pTemplate="separator"> / </ng-template>
</p-breadcrumb>